// Disabling this rule for this file as we need to expose functions
// using the `window.nbc.identity` object
/* eslint-disable class-methods-use-this */

import {
  ConfigHelpers,
  cookieNames,
  DefaultPage,
  SdkMessageCategory,
  SdkMessageType,
  setProfileCompletionStatus,
  setSignUpCompletionStatus,
} from 'sdk-ui-web-library-common'
import jwtDecode from 'jwt-decode'

import IdentityConfig from './configuration/identityConfig'
import IdentityServer from './identityServer'
import IdentityRecord from './identityRecord'
import IdentityWindow from './identityWindow'
import IdentityFrame from './frame/identityFrame'
import LaunchDarklyConfiguration from './configuration/launchDarklyConfiguration'
import { IDENTITY_SDK_VERSION } from './version'

// Networking
import {
  AnalyticsErrorSystem,
  AnalyticsErrorType,
  AnalyticsEventDispatcher,
  AnalyticsService,
  configureIdentitySdk,
  getHealth,
  getSavedProfile,
  getSessionToken,
  IdentitySDK,
  isProfileValid,
  isShippingAddressValid,
  PasskeyAuthMode,
  setLanguageCode,
  setSdkVersion,
  setSessionToken,
  SignOutType,
} from './networking/kmp'
import IdmApiClient from './networking/idm'

// Misc
import Logger from './util/logger'
import WebEventLogger from './eventLogger/WebEventLogger'
import NativeEventLogger from './eventLogger/NativeEventLogger'
import WebRemoteConfigProvider from './remoteConfig/WebRemoteConfigProvider'
import {
  createOptInAgreementCookieName,
  getCookie,
  getExistingOptInAgreementIdentifiers,
  isUserMissingRequiredOptIns,
  removeCookie,
  setCookie,
  setCookieFromBase64,
  setOptinCookiesFromLocalStorage,
  verifyCookieOnInterval,
} from './helpers/cookieHelper'
import {
  getLocalStorageItem,
  localStorageItemNames,
  storeOptInCookie,
} from './helpers/localStorageHelper'
import { getCookieExpirationDate, oneYearFromNowInSeconds } from './helpers/dateHelper'
import { isLocalDomain, isNBCUniDomain } from './helpers/domainHelper'
import { createComputedPropertyLessClone } from './helpers/jsonHelper'
import { isEmpty as isStringEmpty, isInvalidValue } from './helpers/stringHelper'
import { buildProfile } from './helpers/credentialHelper'
import { getNativeBridge } from './nativeBridge'
import { delay } from './helpers/promise'
import {
  fetchAccessTokenHelper,
  generateCodeChallengeAndRedirect,
  generateCodeVerifier,
  isAuthenticatedWithCrossDomain,
  refreshAccessTokenHelper,
} from './helpers/crossDomain'
import {
  enableCovaticSdk,
  getAdMeta,
} from './helpers/covaticHelper'
import {
  AuthenticationState,
  RegistrationResult,
  SdkStatus,
  WindowState,
} from './consts'
import AnalyticsEventLogger from './analytics/analyticsEventLogger'
import CovaticEventLogger from './analytics/covaticEventLogger'
import { buildGPMUrl } from './helpers/manageProfileHelper'
import { createPublicKey } from './helpers/passkeyHelper'
import { getSDKVersionFromScript } from './helpers/versionHelper'

import APIs from './networking/public'

/**
 * Authentication state for the current user.
 * @typedef {String} AuthenticationState
 * @property {String} authenticated
 * @property {String} unauthenticating
 * @property {String} unauthenticated
 * @property {String} unknown
 */

/**
 * Registration result for the current user.
 * @typedef {String} RegistrationResult
 * @property {String} registered
 * @property {String} logged in
 * @property {String} completed
 * @property {String} cancelled
 * @property {String} logged out
 */

/**
 * State of a feature completion based on user behavior. Currently used for
 * {@link Identity#signUpCompletionStatus} & {@link Identity#completeProfileCompletionStatus}
 * @typedef {String} FlowCompletionStatus
 * @property {String} notStarted
 * @property {String} incomplete
 * @property {String} complete
 */

/**
 * Avaialble default page to launch directly
 * @typedef {String} DefaultPage
 * @property {String} logIn
 * @property {String} createProfile
 * @property {String} completeProfile
 * @property {String} addShippingAddress
 */

/**
 * Supported event types that developers can receive notifications for
 * @typedef {String} ObservableType
 * @property {String} status - The status of the SDK. The most up-to-date value is passed
 * as an argument to the callback function. Possible SDK status values are: "uninitialized",
 * "initializing" and "initialized".
 * @property {AuthenticationState} authenticationState - The user's authentication state.
 * The most up-to-date value is passed as an argument to the callback function.
 * Possible authentication state values are: "authenticated", "unauthenticating", "unauthenticated",
 * and "unknown".
 * @deprecated @property {String} user - (Deprecated) The current user. The user is passed as an
 * argument to the callback function. Will be null if user is not authenticated.
 * @property {String} profile - The current profile. The profile is passed as an argument to the
 * callback function. Will be null if user is not authenticated.
 * @property {String} registrationResult - The result from the most recent registration-related API
 * call. Possible registration result values are: "registered", "logged in", "completed",
 * "cancelled", and "logged out".
 */
const ObservableTypes = Object.freeze({
  STATUS: 'status',
  AUTHENTICATION_STATE: 'authenticationState',
  USER: 'user',
  PROFILE: 'profile',
  REGISTRATION_RESULT: 'registrationResult',
  WINDOW_STATE: 'windowState',
})

export default class Identity {
  /**
     * Default observers class variable
     */
  #observers = []

  /**
     * Property that represents the current state of the SDK
     * Changes to this value will be published as 'state' events.
     * @type {String}
     */
  #state = {
    status: SdkStatus.UNINITIALIZED,
    authenticationState: AuthenticationState.UNKNOWN,
    registrationResult: RegistrationResult.LOGGED_OUT,
    profile: null,
    closeReason: null,
    windowState: WindowState.CLOSED,
  }

  /**
     * Wrapper class for {@link Console#log}
     * If enabled, calls to {@link Logger#log} will print to the console.
     * If disabled nothing will happen
     * @type {Logger}
     */
  #logger

  /**
     * Boolean used to determine if checkout UI/UX modifications need to be presented to the user
     * @type {Boolean}
     */
  #checkoutEnabled = false

  /**
     * A specific url host to fetch the configuration file from
     * @type {String}
     */
  #configLocationHost = null

  /**
     * Flag used to indicate whether or not the SDK is running in debug mode
     * @type {Boolean}
     */
  #debug = false

  /**
     * The environment the SDK should run in. Possible values are 'dev', 'stage' and 'production'
     * @type {String}
     */
  #env = null

  /**
     * The name of the configuration file to be fetched
     * @type {String}
     */
  #key = null

  /**
     * The language that should be used to launch the SDK.
     * Currently, only English (en) and Spanish (es) are supported.
     * @type {String}
     */
  #language = null

  /**
     * Boolean indicating whether or not the SDK is running in a native client
     * @type {Boolean}
     */
  #native = false

  /**
     * The version of the SDK to be used
     * @type {String}
     */
  #version = getSDKVersionFromScript()

  /**
     * The Client ID to be used on cross domain mode
     * @type {String}
     */
  #clientId = null

  /**
     * Boolean indicating whether or not the SDK is running in crossDomain mode
     * @type {Boolean}
     */
  #crossDomain = false

  /**
     * Flag that indicates whether the SDK assets/config and iframe should be loaded
     * using the default hostname instead of calculated dynamically.
     */
  #useBaseSdkLocation = false

  /**
     * @type {IdentityConfig}
     * A key/value map of the parsed configuration file.
     * If the SDK is not initialized or if there was a problem fetching the configuration file
     * then this value will be null
     * @type {?IdentityConfig}
     */
  #config = null

  /**
     * The server that config files and other assets are fetched from
     * @type {IdentityServer}
     */
  #server = null

  /**
      * A key/value map of LaunchDarkly configurations
      * @type {LaunchDarklyConfiguration}
      */
  #launchDarklyConfiguration = null

  /**
     * The client used to make api calls to IDM endpoints
     * @type {Object}
     */
  #idmApiClient = null

  /**
     * Controller that facilitates communication between the {@link Identity} class and the window
     * used to launch the SDK.
     * @type {Object}
     */
  #windowController = null

  /**
     * Object that will dispatch events to the correct platform
     * @type {WebEventLogger|NativeEventLogger}
     */
  #eventLogger

  /**
     * Object that will provide the remote config values
     * @type {WebRemoteConfigProvider}
     */
  #remoteConfigProvider

  /**
     * Callback invoked whenever the launched window has loaded
     *
     * @type {Function}
     */
  #onWindowLoadCallback = null

  /**
     * Callback invoked whenever the launched window receives a new message
     *
     * @type {Function}
     */
  #onWindowMessageCallback = null

  /**
     * Callback invoked whenever the current profile changes in the launched window
     * @type {Function}
     */
  #onWindowAuthenticationStateCallback = null

  /**
     * Callback invoked whenever one of the registration api calls returns a result
     * @type {Function}
     */
  #onWindowRegistrationResultCallback

  /**
     * Custom event attributes set and maintained by client that would be sent with all
     * MParticle analytic events
     */
  #customEventAttributes = {}

  #initCompletePromise

  analyticsEventLogger = null

  /**
   * Flag to determine if covatic was corectly initialized
   */
  #covaticInitialized = false

  #covaticEventLogger = null

  /**
   * Controller to abort passkey requests
   * @type {AbortController}
   */
  #passkeyAbortController = null

  #previousMediation = null

  /**
   * Define if the analytics apiKey used is production or stage
   */
  #analyticsEnv = null

  /**
   * Define the local configration to be overrriden after sdk init
   */
  #identityConfigOverrides = null

  constructor() {
    this.#logger = new Logger()
    this.#onWindowLoadCallback = this.#onIframeLoad.bind(this)
    this.#onWindowMessageCallback = this.#onIframeMessage.bind(this)
    this.#onWindowAuthenticationStateCallback = this.#setState.bind(this)
    this.#onWindowRegistrationResultCallback = this.#setState.bind(this)
    this.on(ObservableTypes.AUTHENTICATION_STATE, this.#onAuthenticationStatusChange.bind(this))
  }

  /**
    * Initialize SDK for use
    *
    * This will start by fetching the {@link IdentityConfig} based on the specified env.
    *
    * User will be restored from local storage, provided the data has not
    * timed out according to the {@link IdentityConfig#sessionTimeout}.
    *
    *
    * @param {String} key - NBC App key, e.g: example
    * @param {Object} [options={}] - Options
    * @param {Boolean} [options.checkoutEnabled=false] - If true then checkout UI/UX
    * modifications are shown to user
    * @param {Boolean} [options.configLocationHost=null] - config host location
    * @param {Boolean} [options.debug=false] - If true then enable logging and enable
    * MParticle `isDevelopmentMode`
    * @param {String} [options.env='production'] - Environment to use, e.g: dev/stage/production
    * @param {Boolean} [options.language='en'] - Language to set; Options are 'es' and 'en'
    * @param {Boolean} [options.native=false] - Enable native analytics bridge
    * @param {Boolean} [options.useBaseSdkLocation=false] - Flag used to indicate that the SDK
    * assets/config and iFrame should be loaded using the default hostname instead of calculating
    * it dynamically.
    * @param {Boolean} [options.crossDomain] - Enables crossDomain mode
    * @return {Promise<Identity, Error>} - Promise of initialized Identity
    */
  initialize(key, {
    checkoutEnabled = false,
    configLocationHost = null,
    debug = false,
    env = 'production',
    language = 'en',
    native = false,
    useBaseSdkLocation = false,
    crossDomain = false,
    analyticsEnv = 'production',
    identityConfigOverrides = null,
  } = {}) {
    this.#idmApiClient = new IdmApiClient()
    this.#version = getSDKVersionFromScript()
    setSdkVersion(this.#version)

    return this.#updateConfiguration({
      key,
      checkoutEnabled,
      configLocationHost,
      debug,
      env,
      language,
      native,
      useBaseSdkLocation,
      version: this.#version,
      crossDomain,
      analyticsEnv,
      identityConfigOverrides,
    })
  }

  /**
    * Reconfigures the SDK and fetches an updated configuration file based on
    * the reconfigured values.
    *
    * If the SDK failed to initialize during the call to initialization, the
    * SDK will be initialized if this call succeeds and the profile will be restored
    * from local storage, provided the data has not timed out according to the
    * {@link IdentityConfig#sessionTimeout}.
    *
    *
    * @param {String} key - NBC App key, e.g: example
    * @param {Object} [options={}] - Options
    * @param {Boolean} [options.checkoutEnabled=false] - If true then checkout UI/UX modifications
    * are shown to user
    * @param {Boolean} [options.configLocationHost=null] - config host location
    * @param {Boolean} [options.debug=false] - If true then enable logging and enable MParticle
    * `isDevelopmentMode`
    * @param {String} [options.env='production'] - Environment to use, e.g: dev/stage/production
    * @param {Boolean} [options.language='en'] - Language to set; Options are 'es' and 'en'
    * @param {Boolean} [options.useBaseSdkLocation=false] - Flag used to indicate that the SDK
    * assets/config and iFrame should be loaded using the default hostname instead of calculating
    * it dynamically.
    * @param {Boolean} [options.version] - The version of the SDK to use
    * @param {Boolean} [options.crossDomain=false] - Boolean indicating whether or not the SDK
    * is running in crossDomain mode
    * @return {Promise<Identity, Error>} - Promise of initialized Identity
    */
  async reconfigure({
    key = this.#key,
    checkoutEnabled = this.#checkoutEnabled,
    configLocationHost = this.#configLocationHost,
    env = this.#env,
    language = this.#language,
    useBaseSdkLocation = this.#useBaseSdkLocation,
    version = this.#version,
    crossDomain = this.#crossDomain,
    analyticsEnv = this.#analyticsEnv,
  }, callback = null) {
    try {
      if (
        key === this.#key &&
        checkoutEnabled === this.#checkoutEnabled &&
        configLocationHost === this.#configLocationHost &&
        env === this.#env &&
        language === this.#language &&
        useBaseSdkLocation === this.#useBaseSdkLocation &&
        this.#getVersionNumber(version) === this.#getVersionNumber(this.#version) &&
        crossDomain === this.#crossDomain
      ) {
        if (callback) callback(false)
        return
      }

      await this.#updateConfiguration({
        key,
        checkoutEnabled,
        configLocationHost,
        env,
        language,
        useBaseSdkLocation,
        version,
        crossDomain,
        analyticsEnv,
      })

      if (callback) callback(true)
    } catch (error) {
      this.#logger.log(error)
      if (callback) callback(false)
    }
  }

  async #updateConfiguration({
    key = this.#key,
    checkoutEnabled = this.#checkoutEnabled,
    configLocationHost = this.#configLocationHost,
    debug = this.#debug,
    env = this.#env,
    language = this.#language,
    native = this.#native,
    useBaseSdkLocation = this.#useBaseSdkLocation,
    version = this.#version,
    crossDomain = this.#crossDomain,
    storedConfig = null,
    analyticsEnv = this.#analyticsEnv,
    identityConfigOverrides = null,
  }) {
    this.#key = (key ?? IdentityConfig.getConfigurationKey())
    this.#checkoutEnabled = checkoutEnabled
    this.#configLocationHost = configLocationHost
    this.#debug = window?.localStorage?.getItem('SDK_KMP_DEBUG') === 'true' || debug
    this.#logger.enabled = this.#debug
    this.#env = env
    this.#language = language
    this.#native = native
    this.#useBaseSdkLocation = useBaseSdkLocation
    this.#version = this.#getVersionNumber(version)
    this.#crossDomain = crossDomain || this.#checkUrlParams('crossDomain') === 'true'
    this.#analyticsEnv = analyticsEnv
    this.#identityConfigOverrides = this.#getIdentityConfigOverrides() || identityConfigOverrides

    if (this.#crossDomain) {
      this.#updatePropertiesWithUrlParams()
    }

    setLanguageCode(language)

    const needsInitialization = this.#state.status === SdkStatus.UNINITIALIZED
    if (needsInitialization) {
      this.#setState({ status: SdkStatus.INITIALIZING })
      this.#log('Initializing sdk...')
    }

    if (this.#server) {
      this.#server.updateConfiguration({
        checkoutEnabled: this.#checkoutEnabled,
        env: this.#env,
        useBaseSdkLocation: this.#useBaseSdkLocation,
        sdkVersion: this.#version,
        crossDomain: this.#crossDomain,
      })
    } else {
      this.#server = new IdentityServer({
        checkoutEnabled: this.#checkoutEnabled,
        env: this.#env,
        useBaseSdkLocation: this.#useBaseSdkLocation,
        sdkVersion: this.#version,
        crossDomain: this.#crossDomain,
      })
    }

    this.#log({
      key: this.#key,
      configLocationHost: this.#configLocationHost,
      debug: this.#debug,
      env: this.#env,
      language: this.#language,
      native: this.#native,
      useBaseSdkLocation: this.#useBaseSdkLocation,
      version: this.#version,
    })

    return IdentityConfig.request({
      server: this.#server,
      key: this.#key,
      configLocationHost: this.#configLocationHost,
      language: this.#language,
      storedConfig,
      identityConfigOverrides: this.#identityConfigOverrides,
    }).then((config) => {
      this.#log('config', config)
      this.#config = config
      this.#clientId = config?.crossDomain?.clientId || 'NBCUID'

      if (needsInitialization) {
        verifyCookieOnInterval(
          cookieNames.PARK_FR,
          (parkFr) => this.#updateAuthentication(parkFr),
        )

        const parkFrCookie = getCookie(cookieNames.PARK_FR)
        if (!parkFrCookie) {
          const parkFrToken = getLocalStorageItem(localStorageItemNames.TOKEN, false)
          if (parkFrToken) {
            this.#loginWithToken(parkFrToken)
          }
        }
        setOptinCookiesFromLocalStorage()

        this.#eventLogger = this.#native
          ? new NativeEventLogger(this.#customEventAttributes)
          : new WebEventLogger(
            config.analytics?.mParticle,
            this.#debug,
            this.#env,
            this.#customEventAttributes,
            this.#analyticsEnv,
          )
        this.#eventLogger.addEventListener('READY', async () => {
          await this.#remoteConfigProvider.updateIdentity(this.#checkoutEnabled)
        })

        this.analyticsEventLogger = new AnalyticsEventLogger(this.#eventLogger)
        this.#covaticEventLogger = new CovaticEventLogger()
        this.#remoteConfigProvider = new WebRemoteConfigProvider(
          config.launchdarkly.clientSideID,
          config.brand.source,
          config.brand.product,
          this.#debug,
          this.#checkoutEnabled,
          this.#crossDomain,
        )

        configureIdentitySdk(
          config,
          language,
          this.#debug,
          this.#eventLogger,
          this.#remoteConfigProvider,
          this.#covaticEventLogger,
        )

        const profile = getSavedProfile()
        this.#remoteConfigProvider.setUp(profile, () => {
          this.#launchDarklyConfiguration = new LaunchDarklyConfiguration(
            IdentitySDK.remoteConfig,
          )
          this.#initCompletePromise = delay(100)
            .then(() => {
              // The set state must run a few millis later, otherwise the
              // this.#initCompletePromise promise will return a null value
              // That's why this promise starts with the delay
              this.#setState({ status: SdkStatus.INITIALIZED })

              // When the following call succeed it will mark the promise as resolved
              // And the SDK will be able to continue and display the content
              if (this.#isUserBeenRedirectedFromOauth()) {
                return this.#checkRedirect()
              }
              return this.#validateStoredProfile()
            })

          if (
            this.#launchDarklyConfiguration?.configuration?.dataLayerConfiguration?.covatic &&
            this.#config.covatic
          ) {
            enableCovaticSdk(this.#config.covatic, () => {
              this.#covaticInitialized = true
            })
          }
        })
      } else {
        configureIdentitySdk(
          config,
          language,
          this.#debug,
          this.#eventLogger,
          this.#remoteConfigProvider,
          this.#covaticEventLogger,
        )

        if (this.#windowController) {
          this.#windowController.setConfig(config)
          this.#windowController.setLanguage(language)
          this.#windowController.setNative(native)
          this.#windowController.setCrossDomain(crossDomain)
        }

        this.#remoteConfigProvider.updateCheckoutEnabled(this.#checkoutEnabled)
        this.#remoteConfigProvider.updateIdentity(this.#checkoutEnabled)
      }
    }).catch((error) => {
      this.#log(`${error.name} initializing: ${error.message}`, error)
      this.#setState({ status: SdkStatus.UNINITIALIZED })
      throw error
    })
  }

  #getIdentityConfigOverrides() {
    const config = this.#checkUrlParams('identityConfigOverrides')
    return config ? JSON.parse(atob(config)) : undefined
  }

  #loginWithToken(parkFrToken) {
    return this.#idmApiClient.loginWithToken(parkFrToken)
      .then(
        (session) => {
          this.#setTokenFromSession(session.tokenId)
          return Promise.resolve(session)
        },
      )
      .catch(
        () => {
          this.unauthenticate()
        },
      )
  }

  async loginWithToken(parkFrToken) {
    const response = await this.#loginWithToken(parkFrToken)
    if (response.profile) {
      this.#setState({
        authenticationState: AuthenticationState.AUTHENTICATED,
        registrationResult: RegistrationResult.LOGGED_IN,
        profile: buildProfile(response.profile, ConfigHelpers.getConfigOptIns(this.#config)),
      })
    }
    return response
  }

  #setTokenFromSession(token) {
    setCookie(
      cookieNames.PARK_FR,
      token,
      oneYearFromNowInSeconds(),
      this.#env,
      this.#crossDomain,
    )
    this.#updateAuthentication(token)
  }

  #setupWindowController(useIframe) {
    const props = {
      debug: this.#debug,
      config: this.#config,
      launchDarklyConfiguration: this.#launchDarklyConfiguration,
      onMessage: this.#onWindowMessageCallback,
      onAuthenticationStateChange: this.#onWindowAuthenticationStateCallback,
      onRegistrationResult: this.#onWindowRegistrationResultCallback,
      language: this.#language,
      native: this.#native,
      crossDomain: this.#crossDomain,
      identity: this,
    }

    if (useIframe) props.onIframeLoad = this.#onWindowLoadCallback

    this.#windowController = useIframe ? new IdentityFrame(props) : new IdentityWindow(props)
    this.#windowController.setIdmApiClient(this.#idmApiClient)
  }

  async #validateStoredProfile() {
    const identityRecord = IdentityRecord.restore(this.#config)
    const savedProfile = identityRecord?.profile
    const parkFrCookie = await this.#getParkFR()
    const parkFrCookieEmpty = isStringEmpty(parkFrCookie)
    const shouldValidateAccessToken = isAuthenticatedWithCrossDomain()

    if (savedProfile || !parkFrCookieEmpty) {
      // When the KMP token is empty, but the parkFrCookie is not, we should set the KMP token
      if (isStringEmpty(getSessionToken()) && !parkFrCookieEmpty) {
        setSessionToken(parkFrCookie)
      }

      // When the ParkFR cookie is different from the KMP token but not empty,
      // we should set the KMP token
      if (!parkFrCookieEmpty && getSessionToken() !== parkFrCookie) {
        setSessionToken(parkFrCookie)
      }

      try {
        await this.syncUserProfile()
      } catch (e) {
        // Invalid token is already handled in the sync user profile
        // If the API fail for different reasons (like network), just keep previous session
        if (savedProfile) {
          this.#setState({
            authenticationState: AuthenticationState.AUTHENTICATED,
            registrationResult: RegistrationResult.LOGGED_IN,
            profile: savedProfile,
          })
        } else {
          this.#setState({ authenticationState: AuthenticationState.UNAUTHENTICATED })
        }
      }
    } else if (!shouldValidateAccessToken) {
      this.#setState({ authenticationState: AuthenticationState.UNAUTHENTICATED })
    }
    if (shouldValidateAccessToken) {
      this.#validateAccessToken()
    }
  }

  async #validateParkFrCookieAndProfile() {
    const parkFrCookie = getCookie(cookieNames.PARK_FR)
    const parkFrCookieEmpty = isStringEmpty(parkFrCookie)
    try {
      if (
        !parkFrCookieEmpty &&
        this.#state.authenticationState === AuthenticationState.UNAUTHENTICATED
      ) {
        await this.#validateStoredProfile()
        return !!this.#isProfileComplete(this.profile)
      }
    } catch (e) {
      this.#logger.log('Failed to load profile', e)
    }
    return false
  }

  async #getParkFR() {
    const parkFrCookie = getCookie(cookieNames.PARK_FR)
    if (!isStringEmpty(parkFrCookie)) return parkFrCookie

    if (this.#native) {
      try {
        const bridge = await getNativeBridge()

        if (window.nativeWrapper?.os !== 'iOS') {
          return ''
        }

        const result = await bridge.sendCommandAndWaitResult('GetStoredCredentials')
        const token = result?.payload?.token
        if (isStringEmpty(token) || isInvalidValue(token)) {
          // When the bridge has returned an invalid string but is not empty we must remove
          // the value from the native storage
          if (!isStringEmpty(token)) bridge.sendCommand('RemoveStoredCredentials')
          return ''
        }

        setCookie(cookieNames.PARK_FR, token, oneYearFromNowInSeconds())
        return token
      } catch {
        // Do nothing
      }
    }

    return ''
  }

  /**
     * @deprecated - Use launch instead
     *
     */
  authenticate(options = {}) {
    return this.launch(options)
  }

  /**
   * Launches the SDK's UI authentication flow in an Iframe.
   *
   * A developer may pass an `options` object to pass analytics referral values
   * @param {Object} [options={}] - Options
   * @param {String} [options.referringPage=window.location.href] - String value of referring page
   * @param {String} [options.registrationReferrer=window.location.href] - String value of
   * registration referrer
   * @param {String} [options.redirectUrl=null] - redirect url for OAuth
   * @param {String} [options.brandData=null] - brand optional data string for oAuth
   * @param {String} [options.key=null] - overwrites the brand key used when redirecting
   * to oAuth
   * @param {String} [options.language=null] - overwrites the language used when redirecting
   * to oAuth
   * @param {String} [options.email=null] - brand optional user email for oAuth
   * @param {DefaultPage} [options.defaultPage=null] - Default page to show when the SDK is
   * launched
   */
  launch(options = {}) {
    if (this.#crossDomain && options.defaultPage !== 'createProfileEmailOnly') {
      const { redirectUrl, ...otherOptions } = options

      const checkedRedirectUrl = redirectUrl ?? this.#config?.crossDomain?.redirectUrl

      if (!checkedRedirectUrl) {
        console.error('Failed to open CrossDomain authentication. RedirectURL was not provided!')
        return Promise.reject(new Error('Failed to open CrossDomain authentication. RedirectURL was not provided!'))
      }

      this.#redirect(checkedRedirectUrl, otherOptions, options.email ?? null)
      return Promise.resolve()
    }

    if (options.defaultPage === 'createProfileEmailOnly' && !this.#launchDarklyConfiguration.configuration.isEmailOnlyRegistrationEnabled) {
      // eslint-disable-next-line no-console
      console.error('CreateProfileEmailOnly flow is not enabled in Launch Darkly')
      return false
    }

    if (!this.#config) {
      console.error('SDK has not been initialized')
      return false
    }
    return this.#initCompletePromise
      .then(() => this.#validateParkFrCookieAndProfile())
      .then((userExistsAndProfileCompleted) => {
        const url = this.#prepareFrame(options)
        if (url && !userExistsAndProfileCompleted) {
          this.#windowController.openIframe(url)
          this.#setState({ windowState: WindowState.OPENING, closeReason: null })
        }
      })
      .catch((e) => this.#log('Failed to validateStoredUser', e))
  }

  embed(container, options = {}) {
    return this.#initCompletePromise
      .then(() => this.#validateParkFrCookieAndProfile())
      .then((userExistsAndProfileCompleted) => {
        const url = this.#prepareFrame(options)
        if (url && !userExistsAndProfileCompleted) {
          const iframe = this.#windowController.getIframe(url)
          this.#setState({ windowState: WindowState.OPENING, closeReason: null })
          if (options.className) {
            iframe.classList.add(options.className)
          }
          container.appendChild(iframe)
        }
      })
      .catch((e) => this.#log('Failed to validateStoredUser', e))
  }

  /**
   * Public method to allow brands create a profile with email ony
   * using their own UI elements
   * @param {*} requestParams Object containing {
   *  email,
   *  firstName,
   *  lastName,
   *  registrationReferrer?,
   *  referringPage?,
   *  pageName?,
   *  pageType?
   * }
   * @returns Object {success, error}
   */
  async emailOnlyRegistration({
    pageName = window.document.title,
    pageType = 'Website',
    ...otherParams
  }) {
    try {
      const responseJson = await this.#idmApiClient.registerEmailOnly({
        pageName, pageType, ...otherParams,
      })
      this.#buildProfileAndUpdateState(responseJson, RegistrationResult.REGISTERED)
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  /**
   * Public method to allow brands login with email ony
   * using their own UI elements
   * @param {*} requestParams Object containing {
   *  code,
   *  authToken,
   *  registrationReferrer,
   *  referringPage,
   *  pageName,
   *  pageType
   * }
   * @returns Object {success, error}
   */
  async loginEmailOnly({
    pageName = window.document.title,
    pageType = 'Website',
    ...otherParams
  }) {
    if (this.#crossDomain) {
      return { success: false, error: 'This feature is not available if the SDK is initialized as crossDomain' }
    }
    try {
      const responseJson = await this.#idmApiClient.loginWithOneTimeCode({
        pageName, pageType, ...otherParams,
      })
      this.#buildProfileAndUpdateState(responseJson.profile, RegistrationResult.LOGGED_IN)
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  /**
   * Public method that allows brands to request OTC
   * using their own UI elements
   * @param {String} email - Email to send the OTC
   * @returns Object { endpoint, response: { success, error, json } }
   */
  async requestOneTimeCode(email) {
    try {
      const responseJson = await this.#idmApiClient.requestOneTimeCode(email)
      const value = { endpoint: null, response: { success: true, error: null, json: responseJson } }
      return value
    } catch (error) {
      return { success: false, error }
    }
  }

  #buildProfileAndUpdateState(responseJson, registrationResultValue) {
    const profile = buildProfile(
      responseJson,
      ConfigHelpers.getConfigOptIns(this.#config),
    )
    if (registrationResultValue === RegistrationResult.LOGGED_IN) {
      this.#onWindowAuthenticationStateCallback({
        authenticationState: AuthenticationState.AUTHENTICATED,
        profile,
      })
    }
    if (registrationResultValue === RegistrationResult.REGISTERED) {
      this.#onWindowRegistrationResultCallback({
        registrationResult: registrationResultValue,
      })
    }
  }

  #openGPM(pathname, brand = this.#config.brand.source) {
    this.#initCompletePromise
      .then(() => {
        if (this.authenticationState !== AuthenticationState.AUTHENTICATED) {
          return Promise.reject(Error('Cannot open manage profile without being authenticated'))
        }

        const language = this.#config.language || this.#language
        const url = buildGPMUrl(this.#env, language, brand, pathname)

        let target
        let features
        const { gpmRedirectionStrategy } = this.#launchDarklyConfiguration?.configuration ?? {}
        if (gpmRedirectionStrategy === 'popup') {
          target = 'GPM'
          const width = window.outerWidth * 0.8
          const height = window.outerHeight * 0.8
          const left = window.screenX + ((window.outerWidth - width) / 2)
          const top = window.screenY + ((window.outerHeight - height) / 4)
          features = `width=${width},height=${height},top=${top},left=${left}`
        } else if (gpmRedirectionStrategy === 'new-tab') target = '_blank'
        else target = '_self'

        return window.open(url, target, features)
      })
  }

  openManageProfile(brand) {
    this.#openGPM('/my-account', brand)
  }

  openEmailPreferences(brand) {
    this.#openGPM('/email-preferences', brand)
  }

  async #checkCrossDomain() {
    let response = {
      status: 'unauthenticated',
      profile: null,
    }

    try {
      await this.#validateStoredProfile()
      if (this.#state.profile) {
        response = {
          status: 'authenticated',
          profile: this.#state.profile,
        }
      }
    } catch (e) {
      // Do nothing, use unauthenticated response
    }

    const frameWindow = this.#windowController.getWindow()
    frameWindow.postMessage({
      type: SdkMessageType.CROSS_APP_LOGIN,
      category: SdkMessageCategory.RESPONSE,
      value: response,
    }, this.#windowController.targetOrigin)
  }

  #redirect(redirectUrl, options, email, defaultPage) {
    const verifier = generateCodeVerifier()
    const host = this.#getAuthenticationUrl()

    let version = this.#version
    const { useQueryStringSDKVersion } = this.#launchDarklyConfiguration?.configuration ?? {}
    if (this.#crossDomain && useQueryStringSDKVersion) {
      version = `v${IDENTITY_SDK_VERSION}`
    }

    const params = {
      native: this.#native,
      useBaseSdkLocation: this.#useBaseSdkLocation,
      version,
      checkoutEnabled: this.#checkoutEnabled,
      configLocationHost: this.#configLocationHost,
      debug: this.#debug,
      env: this.#env,
      redirectUrl,
      language: options.language || this.#language,
      key: options.key || this.#key,
      email: email || options.email,
      defaultPage,
      identityConfigOverrides: this.#identityConfigOverrides
        ? btoa(JSON.stringify(this.#identityConfigOverrides)) : undefined,
    }

    if (
      this.#launchDarklyConfiguration?.configuration?.deviceidOAuth &&
      window.mParticle &&
      typeof window.mParticle?.getDeviceId === 'function'
    ) {
      const deviceId = window.mParticle.getDeviceId()
      if (deviceId) {
        params.mParticleDeviceID = deviceId
      } else {
        console.warn('Continue to redirect to OAuth without mParticle Device ID')
      }
    } else {
      console.warn('mParticle is not ready, Continue to redirect to OAuth without mParticle Device ID')
    }

    generateCodeChallengeAndRedirect(
      verifier,
      host.href,
      params,
      options,
    )
  }

  #checkRedirect() {
    if (window.location && window.location.search) {
      const urlParams = window.location.search
      const code = new URLSearchParams(urlParams).get('code')
      if (urlParams !== '' && code) {
        const stateParam = new URLSearchParams(urlParams).get('state')
        const decodedParam = JSON.parse(atob(stateParam))
        const { redirectUrl, completeProfileStatus, signUpCompleteStatus } = decodedParam
        if (completeProfileStatus) {
          setProfileCompletionStatus(completeProfileStatus)
        }
        if (signUpCompleteStatus) {
          setSignUpCompletionStatus(signUpCompleteStatus)
        }
        const optIns = decodedParam.optIn
        const { brandData } = decodedParam
        this.#fetchAccessToken(redirectUrl, code, optIns, brandData)
      } else {
        this.#validateAccessToken()
      }
    }
  }

  #isUserBeenRedirectedFromOauth() {
    let validationResult = false
    if (window.location && window.location.search) {
      const urlParams = window.location.search
      const searchParams = new URLSearchParams(urlParams)
      validationResult = (searchParams.get('code') && searchParams.get('state')) ?? false
    }
    return validationResult
  }

  #updatePropertiesWithUrlParams() {
    if (window.location && window.location.search) {
      const urlParams = window.location.search
      const paramObj = new URLSearchParams(urlParams)
      this.#native = paramObj.get('native') === 'true'
      this.#useBaseSdkLocation = paramObj.get('useBaseSdkLocation') === 'true'
      if (paramObj.get('version')) this.#version = this.#getVersionNumber(paramObj.get('version'))
      this.#checkoutEnabled = paramObj.get('checkoutEnabled') === 'true'
      this.#configLocationHost = paramObj.get('configLocationHost') ? paramObj.get('configLocationHost') : this.#configLocationHost
      this.#debug = paramObj.get('debug') === 'true'
      this.#env = paramObj.get('env') ? paramObj.get('env') : this.#env
      this.#language = paramObj.get('language') ? paramObj.get('language') : this.#language
      this.#key = paramObj.get('key') ? paramObj.get('key') : this.#key
      this.#clientId = paramObj.get('clientId') ? paramObj.get('clientId') : this.#clientId
      const finalRegistrationReferrer = paramObj.get('registrationReferrer') ?? window.location.href
      const finalReferringPage = paramObj.get('referringPage') ?? window.location.href
      this.#windowController?.setReferralInfo(finalRegistrationReferrer, finalReferringPage)
      this.#idmApiClient?.setReferralInfo(finalRegistrationReferrer, finalReferringPage)
    }
  }

  #fetchAccessToken(redirectUrl, code, optIns, brandData) {
    const frApiUrl = this.#config.forgeRock.apiUrl
    const frApiVersion = this.#config.forgeRock.apiVersion
    const brandSource = this.#config.brand.source
    fetchAccessTokenHelper(code, redirectUrl, frApiUrl, this.#clientId, frApiVersion, brandSource)
      .then(
        async (resp) => {
          const jsonResponse = await resp.json()
          this.#setCookiesFromToken(jsonResponse, optIns)
          window.history.pushState(null, document.title, `${redirectUrl}?brandData=${brandData}`)
          this.#getProfileWithAccessToken()
        },
      )
  }

  async #extractSessionToken(parkAccess) {
    if (!getCookie(cookieNames.PARK_FR)) {
      setSessionToken(parkAccess)
    }
    try {
      const newProfileFound = await this.#getProfileWithAccessToken()
      if (!newProfileFound) {
        throw new Error('Not Found')
      }
    } catch (e) {
      setSessionToken(parkAccess)
      removeCookie(cookieNames.PARK_FR_ACCESS, this.#env, this.#crossDomain)
      this.#getProfileWithRefreshToken()
    }
  }

  async #validateAccessToken() {
    if (window?.location) {
      if (!window.location.hostname.includes('nbcuni.com')) {
        const parkAccess = getCookie(cookieNames.PARK_FR_ACCESS)

        if (this.authenticationState === AuthenticationState.AUTHENTICATED) {
          // When user is already authenticated WITHOUT OAuth, we should
          // prevent to revalidate user session
          if (!isAuthenticatedWithCrossDomain()) return

          // When the user is already authenticated WITH OAuth and has parkAccess
          // present, we should prevent revalidate. But if it is missing, we must
          // revalidate to generate again.
          if (!isStringEmpty(parkAccess, true)) {
            return
          }
        }

        if (parkAccess) {
          this.#extractSessionToken(parkAccess)
        } else {
          this.#getProfileWithRefreshToken()
        }
      }
    }
  }

  async #getProfileWithAccessToken() {
    try {
      const responseJson = await this.#idmApiClient.getProfile({
        pageName: window.document.title,
        pageType: 'Website',
        referringPage: 'None',
      })
      if (responseJson) {
        this.#setState({
          authenticationState: AuthenticationState.AUTHENTICATED,
          registrationResult: RegistrationResult.LOGGED_IN,
          profile: buildProfile(responseJson, ConfigHelpers.getConfigOptIns(this.#config)),
        }, true) // Don't set park-fr since we are comming from oauth

        return true
      }
      return false
    } catch (error) {
      return false
    }
  }

  async #getProfileWithRefreshToken() {
    const parkRefresh = getCookie(cookieNames.PARK_FR_REFRESH)
    if (parkRefresh) {
      try {
        const newTokens = await this.#refreshAccessToken({ refreshToken: parkRefresh })
        await this.#setCookiesFromToken(newTokens)
        const parkAccess = getCookie(cookieNames.PARK_FR_ACCESS)

        if (parkAccess) {
          this.#extractSessionToken(parkAccess)
        } else {
          this.#getProfileWithRefreshToken()
        }
      } catch (error) {
        this.#setState({ authenticationState: AuthenticationState.UNAUTHENTICATED })
      }
    }
    this.#setState({ authenticationState: AuthenticationState.UNAUTHENTICATED })
  }

  async #refreshAccessToken(token) {
    const response = await refreshAccessTokenHelper(
      token,
      this.#config.forgeRock.apiUrl,
      this.#clientId,
      this.#config.forgeRock.apiVersion,
      this.#config.brand.source,
    )
    return response.json()
  }

  async #setCookiesFromToken(token, optIns = null) {
    const time = new Date().getTime() / 1000
    const accessTokenDecoded = jwtDecode(token.access_token)
    const refreshTokenDecoded = jwtDecode(token.refresh_token)
    const accessTokenExpires = time + (accessTokenDecoded.expires_in * 60)
    const refreshTokenExpires = time + (refreshTokenDecoded.expires_in * 60)
    if (optIns !== null) {
      setCookieFromBase64(optIns, accessTokenExpires, this.#env, this.#crossDomain)
    }
    const refresToken = token.refresh_token
    const accessToken = token.access_token
    setCookie(
      cookieNames.PARK_FR_ACCESS,
      accessToken,
      accessTokenExpires,
      this.#env,
      this.#crossDomain,
    )
    setCookie(
      cookieNames.PARK_FR_REFRESH,
      refresToken,
      refreshTokenExpires,
      this.#env,
      this.#crossDomain,
    )
    setSessionToken(token.access_token)
  }

  #checkUrlParams(param) {
    if (window.location && window.location.search) {
      const values = window.location.search
      const urlParams = new URLSearchParams(values)
      return urlParams.get(param)
    }
    return false
  }

  #prepareFrame({ registrationReferrer = null, referringPage = null, defaultPage = null } = {}) {
    if (this.#state.status !== SdkStatus.INITIALIZED) {
      return console.error('launch() can only be called once the sdk has been initialized')
    }

    const defaultPageWhitelist = Object.values(DefaultPage)
    if (!defaultPage) defaultPage = this.getSuggestedDefaultPage()
    if (!defaultPageWhitelist.includes(defaultPage)) {
      return console.error(
        // eslint-disable-next-line max-len
        `Identity.authenticate error: make sure the defaultPage property is set to one of the following: ${defaultPageWhitelist.join(', ')}`,
      )
    }

    if (!this.#windowController) this.#setupWindowController(true)

    const finalRegistrationReferrer = registrationReferrer ?? window.location.href
    const finalReferringPage = referringPage ?? window.location.href

    const url = this.#getAuthenticationUrl()
    url.searchParams.append('sdkLoaded', 'true')
    url.searchParams.append('defaultPage', defaultPage)
    this.#windowController.setTarget(url.origin)
    this.#windowController.setReferralInfo(finalRegistrationReferrer, finalReferringPage)
    this.#idmApiClient.setReferralInfo(finalRegistrationReferrer, finalReferringPage)
    return url
  }

  link() {
    if (this.#state.status !== SdkStatus.INITIALIZED) {
      console.error('link() can only be called once the sdk has been initialized')
      return
    }

    const authenticationUrl = this.#getAuthenticationUrl()
    if (authenticationUrl.origin !== window.origin) {
      console.error(`link() can only be called from ${authenticationUrl}`)
      return
    }

    if (!this.#windowController) {
      this.#setupWindowController(false)
      this.#updatePropertiesWithUrlParams()
      this.#windowController.setTarget(authenticationUrl.origin)
      this.#windowController.enableMessaging()
      this.#windowController.configureWindow()
    }
  }

  #getAuthenticationUrl() {
    const { useQueryStringSDKVersion } = this.#launchDarklyConfiguration?.configuration ?? {}

    if (window !== undefined && window.location) {
      if (this.#crossDomain || window.location.href.includes('nbcuni.com')) {
        let urlPrefix

        if (!this.#useBaseSdkLocation && isLocalDomain()) {
          urlPrefix = `${window.location.hostname}:4000`
        } else if (this.#env === 'production') {
          urlPrefix = 'id.nbcuni.com'
        } else {
          urlPrefix = `${this.#env}.id-envs.nbcuni.com`
        }

        if (this.#crossDomain && useQueryStringSDKVersion && !isLocalDomain()) {
          return new URL(`https://${urlPrefix}/iam/authenticate/`)
        }
        return new URL(`https://${urlPrefix}/websdk/v${this.#version}/authenticate/`)
      }
    }

    if (!this.#useBaseSdkLocation && isLocalDomain()) {
      return new URL(`https://${window.location.hostname}:4000`)
    }

    return this.#server.getSdkUrl(`v${this.#version}/authenticate/`)
  }

  /**
   * Reconfigres the SDK using a config from localStorage
   *
   */
  async reconfigureFromLocalStorage() {
    if (this.#env === 'production') {
      this.#log({
        type: 'ERROR:',
        info: 'Feature only available on dev and stage env',
      })
      return
    }
    const configValues = getLocalStorageItem('sdkLocalConfig')
    if (configValues) {
      await this.#updateConfiguration({ storedConfig: configValues })
      this.#windowController.setConfig(this.#config)
      this.#windowController.configureWindow()
    }
  }

  /**
     * Returns the current Sign Up state
     * @returns {FlowCompletionStatus}
     */
  signUpCompletionStatus() {
    return AnalyticsService.signUpCompletionStatus()
  }

  /**
     * Returns current Complete Profile state
     * @returns {FlowCompletionStatus}
     */
  completeProfileCompletionStatus() {
    return AnalyticsService.completeProfileCompletionStatus()
  }

  getSuggestedDefaultPage() {
    if (this.#canAccessProfile(false)) {
      const { profile } = this
      if (!this.#isProfileComplete(profile)) return DefaultPage.COMPLETE_PROFILE
      if (!this.#isShippingAddressComplete(profile)) return DefaultPage.ADD_SHIPPING_ADDRESS
      return DefaultPage.CREATE_PROFILE_FIRST_PARTY
    } return DefaultPage.CREATE_PROFILE_FIRST_PARTY
  }

  /**
     * Signs a user out of the SDK and removes user data from localStorage.
     *
     * @param {String} pageName - The name of the page that this method was called from. If not
     * specified, the default value is the title of the current document
     * @param {String} pageType - The type of page that this method was called from. If not
     * specified, the default value is 'Website'
     */
  unauthenticate(pageName = window.document.title, pageType = 'Website') {
    return this.#unauthenticate(SignOutType.Manual, pageName, pageType)
  }

  async #unauthenticate(signOutType, pageName = window.document.title, pageType = 'Website') {
    if (this.#state?.authenticationState !== AuthenticationState.AUTHENTICATED &&
      (!this.profile || this.profile.signupType !== 'email-only')) {
      this.#log('SDK is not authenticated, ignoring unauthenticate call')
      return
    }

    this.#setState({ authenticationState: AuthenticationState.UNAUTHENTICATING })

    this.#idmApiClient.logout({
      pageName,
      pageType,
      signOutType,
      oauthData: {
        parkFrAccess: getCookie(cookieNames.PARK_FR_ACCESS),
        parkFrRefresh: getCookie(cookieNames.PARK_FR_REFRESH),
        clientId: this.#config.crossDomain?.clientId,
      },
    })

    this.#handleLogoutSuccess()
  }

  updateProfile(
    {
      firstName,
      lastName,
      telephoneNumber,
      postalAddress,
      address2,
      city,
      stateProvince,
      zipCode,
      birthYear,
      gender,
    } = {},
    pageName = window.document.title,
    pageType = null,
  ) {
    if (this.#canAccessProfile()) {
      const updatedFields = {
        firstName,
        lastName,
        telephoneNumber,
        postalAddress,
        address2,
        city,
        state:
          stateProvince,
        zipCode,
        birthYear,
        gender,
      }

      return this.#idmApiClient.updateProfileFromPublicApi(
        { profile: this.profile, updatedFields },
        pageName,
        pageType,
      ).then((response) => {
        if (this.authenticationState === AuthenticationState.AUTHENTICATED) {
          const profile = buildProfile(response, ConfigHelpers.getConfigOptIns(this.#config))
          this.#setState({ profile })
          return profile
        }
        throw Error('Profile could not be updated locally because the user is no longer authenticated')
      })
    }

    return Promise.reject(new Error('User is unauthenticated or has an invalid profile'))
  }

  /**
   * Method exposed to get the audience from covatic
   * @returns {[String] | null}
   */
  getAudience() {
    if (this.#covaticInitialized) {
      const adMeta = getAdMeta()
      if (adMeta.length > 0) {
        const audience = adMeta.filter((row) => row.belongsTo === 'true')
          .map((row) => row.profileName)
        return audience
      }
      return []
    }
    return []
  }

  //
  //  TODO: Delete Profile Functionality
  /**
  //  * Deletes the profile of the currently authenticated user. Upon deletion, the user is
  //  * signed out of the SDK and their profile data is removed from localStorage
  //  *
  //  * A developer may pass an `options` object to pass analytics referral values
  //  * @param {Object} [options={}] - Options
  //  * @param {String} [options.pageName=window.document.title] - The name of the page that this
  //  * method was called from
  //  * @param {String} [options.pageType='Website'] - The type of page that this method was called
  //  *
  //  * @returns {Promise}
  //  */
  // deleteProfile({ pageName = window.document.title, pageType = 'Website' } = {}) {
  //     this.#setState({ authenticationState: AuthenticationState.UNAUTHENTICATING })
  //     return this.#idmApiClient.deleteProfile({ pageName, pageType }).then(() => {
  //         this.#handleLogoutSuccess()
  //     }).catch(error => {
  //         this.#log('delete profile failed', error)
  //         throw error
  //     })
  // }

  #handleLogoutSuccess() {
    this.#log('Removing profile...')

    if (this.#native && window.nativeWrapper?.os === 'iOS') {
      getNativeBridge()
        .then((bridge) => bridge.sendCommand('RemoveStoredCredentials'))
    }

    removeCookie(cookieNames.PARK_FR)
    removeCookie(cookieNames.PARK_FR_ACCESS)
    removeCookie(cookieNames.PARK_FR_REFRESH)

    this.#setState({
      authenticationState: AuthenticationState.UNAUTHENTICATED,
      profile: null,
      registrationResult: RegistrationResult.LOGGED_OUT,
    })
  }

  /**
     *
     * Performs a health check of the IDM system used by the current environment
     * @returns {Promise<Boolean>} A promise with the server's status. If there are no issues,
     * the status will be true
     */
  healthCheck(environment = this.#env) {
    return getHealth(environment === 'local' ? 'dev' : environment)
  }

  get status() {
    return this.#state.status
  }

  /**
     * Getter that returns the current authentication state.
     * @returns {AuthenticationState}
     */
  get authenticationState() {
    return this.#state.authenticationState
  }

  /**
     * Getter that returns the current registration result.
     * @returns {RegistrationResult}
     */
  get registrationResult() {
    return this.#state.registrationResult
  }

  /**
     * Getter that returns the current session token. If the SDK is not initialized this getter
     * returns null. It will also return null if the user is unauthenticated.
     * @returns {?String}
     */
  get token() {
    if (this.authenticationState !== AuthenticationState.AUTHENTICATED) return null
    return getSessionToken()
  }

  getOneTrustToken() {
    if (this.authenticationState !== AuthenticationState.AUTHENTICATED) return Promise.resolve(null)
    return this.#idmApiClient.getOneTrustToken()
  }

  /**
     * @deprecated - Use profile instead
     * Returns the current profile. If the user is not authenticated, then this getter returns null.
     * @returns {?Object}
     */
  get user() {
    console.warn('WARNING! Obsolete property called. Please use the new profile property instead!')
    return this.profile
  }

  get profile() {
    return this.#state.profile
  }

  /**
     * Returns current language set on the SDK via {@link Identity#initialize}
     * or via {@link Identity#reconfigure}
     */
  get language() {
    return this.#language
  }

  get launchDarklyConfiguration() {
    if (this.status !== SdkStatus.INITIALIZED) {
      return console.error('SDK has not been initialized')
    }
    return {
      configs: this.#remoteConfigProvider.allFlagsWithoutOverrides(),
      overrides: this.#remoteConfigProvider.allOverrides(),
    }
  }

  overrideLaunchDarklyConfigurations(overrides) {
    if (this.status !== SdkStatus.INITIALIZED) {
      console.error('SDK has not been initialized')
      return
    }
    if (overrides) {
      this.#remoteConfigProvider.setOverrides(overrides)
    } else {
      this.#remoteConfigProvider.resetOverrides()
    }
    this.#launchDarklyConfiguration.refresh()
  }

  /**
     * Helper function that checks the validity of a user's profile. A user's profile is considered
     * valid if all the fields required by the Launch Darkly First Party Data Configuration are
     * present in the profile. If all required fields are present in the profile, this function
     * returns true, else it returns false.
     * @returns {Boolean}
     * @throws Will throw an error if the SDK is not initialized or if the
     * {@link Identity#profile} is null
     */
  isUserProfileValid() {
    if (this.#canAccessProfile()) {
      const { profile } = this
      return this.#isProfileComplete(profile) && this.#isShippingAddressComplete(profile)
    }

    return false
  }

  /**
   * Helper function to synchronize the user profile with the server. If there are new changes,
   * the local version will be updated.
   *
   * @param {Object} options - Options
   * @param {String} options.pageName - The name of the page that this method was called from.
   * If not specified, the default value is the title of the current document
   * @param {String} options.pageType - The type of page that this method was called from. If not
   * specified, the default value is 'Website'
   * @param {String} options.referringPage - The previous page that the user was on. If not
   * specified, the default value is 'None'
   *
   * If the user token is no longer valid, the user will be automatically logged out.
   * @returns Promise<Void> Promise to handle possible issues (like network errors)
   */
  async syncUserProfile({
    pageName = window.document.title,
    pageType = 'Website',
    referringPage = 'None',
  } = {}) {
    try {
      const responseJson = await this.#idmApiClient.getProfile({
        pageName,
        pageType,
        referringPage,
      })
      if (responseJson) {
        this.#setState({
          authenticationState: AuthenticationState.AUTHENTICATED,
          registrationResult: RegistrationResult.LOGGED_IN,
          profile: buildProfile(responseJson, ConfigHelpers.getConfigOptIns(this.#config)),
        })
      } else if (!isAuthenticatedWithCrossDomain()) {
        this.#setState({ authenticationState: AuthenticationState.UNAUTHENTICATED })
      }
    } catch (e) {
      const errorCode = e?.response?.error?.code

      // In case sync profile call fail by invalid token, it will return http 401 or 404
      if (errorCode === 401 || errorCode === 404) {
        this.#setState({ authenticationState: AuthenticationState.UNAUTHENTICATED })
      } else {
        // In case it fail, we raise the error so the caller can handle
        throw e
      }
    }
  }

  /**
   * Checks if the current session is still valid.
   *
   * @param {Object} options - Options
   * @param {String} options.pageName - The name of the page that this method was called from.
   * If not specified, the default value is the title of the current document
   * @param {String} options.pageType - The type of page that this method was called from. If not
   * specified, the default value is 'Website'
   * @param {String} options.referringPage - The previous page that the user was on. If not
   * specified, the default value is 'None'
   *
   * @returns Promise<Boolean> boolean value if the session is still valid or not
   */
  validateSession({
    pageName = window.document.title,
    pageType = 'Website',
    referringPage = 'None',
  } = {}) {
    return this.#idmApiClient.validateSession({ pageName, pageType, referringPage })
      .then((isValid) => {
        if (!isValid) {
          this.#setState({ authenticationState: AuthenticationState.UNAUTHENTICATED })
        }
        return Promise.resolve(isValid)
      })
  }

  #canAccessProfile(displayErrors = true) {
    const { status, profile } = this.#state
    if (status !== SdkStatus.INITIALIZED) {
      return displayErrors ? console.error('SDK has not been initialized') : false
    } if (!profile) {
      return displayErrors ? console.error('User has not been authenticated') : false
    }

    return true
  }

  #isProfileComplete(profile) {
    const optInAgreementsMissing = isUserMissingRequiredOptIns(this.#config, this.profile?._id)
    return isProfileValid(
      profile,
      this.#launchDarklyConfiguration.firstPartyDataInputConfiguration,
    ) && !optInAgreementsMissing
  }

  #isShippingAddressComplete(profile) {
    return !this.#launchDarklyConfiguration.configuration.shippingAddressConfiguration.required ||
      isShippingAddressValid(profile)
  }

  async #handlePasskeyAuthentication(mediationType) {
    const frameWindow = this.#windowController.getWindow()
    let authId
    let initResponse

    const handlePasskeyAuthError = (err, errorSystem) => {
      if (authId) {
        this.#idmApiClient.finalizePasskeyAuthentication(
          authId,
          { errorMessage: err },
        )

        authId = null
      }

      frameWindow?.postMessage({
        type: SdkMessageType.PASSKEY_AUTH_CREDS_RESPONSE,
        category: SdkMessageCategory.RESPONSE,
        value: {
          error: err,
          success: false,
          errorSystem,
        },
      }, this.#windowController.targetOrigin)
    }

    try {
      initResponse = await this.#idmApiClient.initPasskey(PasskeyAuthMode.AUTHENTICATION)
    } catch (error) {
      AnalyticsEventDispatcher.logError(
        error?.response?.error?.description ?? error,
        AnalyticsErrorType.PASSKEY,
        AnalyticsErrorSystem.IDM,
      )
      return
    }

    try {
      this.#passkeyAbortController?.abort()
      this.#passkeyAbortController = new AbortController()

      authId = initResponse.authcontext.authId
      const { metadata } = initResponse.authcontext
      const challenge = Uint8Array.from(atob(metadata.challenge), (c) => c.charCodeAt(0))

      let newCredentialInfo
      try {
        newCredentialInfo = await navigator.credentials.get({
          mediation: mediationType,
          signal: this.#passkeyAbortController.signal,
          publicKey: {
            challenge,
            allowCredentials: metadata._allowCredentials,
            timeout: metadata.timeout,
            rpId: metadata._relyingPartyId,
            userVerification: metadata.userVerification,
          },
        })
      } catch (err) {
        handlePasskeyAuthError(err, createComputedPropertyLessClone(AnalyticsErrorSystem.PASSKEY))
        return
      }

      frameWindow?.postMessage({
        type: SdkMessageType.PASSKEY_LOADING,
        category: SdkMessageCategory.RESPONSE,
        value: { isLoading: true },
      }, this.#windowController.targetOrigin)

      const rawId = newCredentialInfo.id
      const clientData = String.fromCharCode.apply(
        null,
        new Uint8Array(newCredentialInfo.response.clientDataJSON),
      )
      const authenticatorData =
        new Int8Array(newCredentialInfo.response.authenticatorData).toString()
      const signature =
        new Int8Array(newCredentialInfo.response.signature).toString()
      const { userHandle } = newCredentialInfo.response

      const requestAuthId = authId
      authId = null

      const userHandleString = btoa(String.fromCharCode.apply(null, new Uint8Array(userHandle)))

      const response = await this.#idmApiClient.finalizePasskeyAuthentication(
        requestAuthId,
        { clientData, authenticatorData, signature, rawId, userHandle: userHandleString },
      )

      if (response.profile) {
        const profile = buildProfile(response.profile, ConfigHelpers.getConfigOptIns(this.#config))
        this.#windowController?.updateProfile(profile)
        this.#setState({
          authenticationState: AuthenticationState.AUTHENTICATED,
          registrationResult: RegistrationResult.LOGGED_IN,
          profile,
        })
      }

      frameWindow?.postMessage({
        type: SdkMessageType.PASSKEY_AUTH_CREDS_RESPONSE,
        category: SdkMessageCategory.RESPONSE,
        value: {
          response,
          success: true,
        },
      }, this.#windowController.targetOrigin)
    } catch (err) {
      handlePasskeyAuthError(err, createComputedPropertyLessClone(AnalyticsErrorSystem.IDM))
    }
  }

  #setState(
    state,
    // DO NOT set park fr cookie if user is authenticated in oauth on the client site
    shouldAvoidSettingParkFrCookie = false,
  ) {
    this.#log('state:', state)
    const oldState = { ...this.#state }
    this.#state = { ...oldState, ...state }
    this.#log('updated state:', this.#state)

    const {
      status,
      authenticationState,
      registrationResult,
      profile,
      windowState,
      closeReason,
    } = this.#state

    if (oldState.status !== status) {
      this.#notify(ObservableTypes.STATUS, status)
    }

    if (oldState.authenticationState !== authenticationState) {
      if (authenticationState === AuthenticationState.AUTHENTICATED) {
        const token = getSessionToken()
        if (!(isStringEmpty(token) || isInvalidValue(token))) {
          if (!shouldAvoidSettingParkFrCookie) {
            setCookie(
              cookieNames.PARK_FR,
              token,
              oneYearFromNowInSeconds(),
              this.#env,
              this.#crossDomain,
            )
            if (isNBCUniDomain()) {
              setCookie(
                cookieNames.I_PLANET_DIRECTORY_PRO,
                token,
                oneYearFromNowInSeconds(),
                this.#env,
                this.#crossDomain,
              )
            }
          }

          if (this.#native && window.nativeWrapper?.os === 'iOS') {
            // iOS contract requires token and value keys
            // We decided to send empty value object as we don't use this object
            const payload = { token, value: {} }
            getNativeBridge()
              .then((bridge) => bridge.sendCommandAndWaitResult('StoreCredentials', payload))
          }
        }
      } else if (authenticationState === AuthenticationState.UNAUTHENTICATED) {
        removeCookie(cookieNames.PARK_FR, this.#env, this.#crossDomain)
        removeCookie(cookieNames.I_PLANET_DIRECTORY_PRO, this.#env, this.#crossDomain)
        removeCookie(cookieNames.PARK_FR_ACCESS, this.#env, this.#crossDomain)
        removeCookie(cookieNames.PARK_FR_REFRESH, this.#env, this.#crossDomain)

        if (this.#native && window.nativeWrapper?.os === 'iOS') {
          // When we fail to get user profile with saved token we must remove stored
          // credentials from the native storage as we do for park-fr
          getNativeBridge()
            .then((bridge) => bridge.sendCommand('RemoveStoredCredentials'))
        }
      }

      this.#notify(ObservableTypes.AUTHENTICATION_STATE, authenticationState)
    }

    if (
      oldState.authenticationState === authenticationState &&
      authenticationState === AuthenticationState.AUTHENTICATED
    ) {
      if (this.#crossDomain) {
        const token = getSessionToken()
        setCookie(
          cookieNames.PARK_FR,
          token,
          oneYearFromNowInSeconds(),
          this.#env,
          this.#crossDomain,
        )
        if (isNBCUniDomain()) {
          setCookie(
            cookieNames.I_PLANET_DIRECTORY_PRO,
            token,
            oneYearFromNowInSeconds(),
            this.#env,
            this.#crossDomain,
          )
        }
      }
    }

    if (oldState.registrationResult !== registrationResult) {
      this.#notify(ObservableTypes.REGISTRATION_RESULT, registrationResult)
    }

    if (oldState.profile !== profile) {
      this.#notify(ObservableTypes.USER, profile)
      this.#notify(ObservableTypes.PROFILE, profile)
    }

    if (oldState.windowState !== windowState) {
      let extras

      switch (windowState) {
        case WindowState.CLOSED:
          extras = { closeReason }
          break
        default:
          extras = {}
          break
      }

      this.#notify(ObservableTypes.WINDOW_STATE, windowState, extras)
    }
  }

  /**
     * Log arguments with Identity prefix
     */
  #log(...args) {
    this.#logger.log(...args)
  }

  #onIframeLoad() {
    this.#setState({ windowState: WindowState.OPENED, closeReason: null })
  }

  /**
     * Handle receiving messages from iframe window
     *
     * @param {String} message - The message object sent to the iframe.
     */
  async #onIframeMessage(message) {
    if (message.data.type === SdkMessageType.TOKEN) {
      const token = getLocalStorageItem(localStorageItemNames.TOKEN, false)

      const frameWindow = this.#windowController.getWindow()
      if (frameWindow) {
        window.postMessage({
          type: SdkMessageType.TOKEN,
          category: SdkMessageCategory.RESPONSE,
          value: {
            idToken: token,
          },
        }, this.targetOrigin)
      }
    }
    if (message.data.type === SdkMessageType.CROSS_APP) {
      this.#checkCrossDomain()
    }
    if (message.data.type === SdkMessageType.VERIFY_PROFILE_COMPLETION_STATUS &&
      message.data.category === SdkMessageCategory.REQUEST) {
      const frameWindow = this.#windowController.getWindow()
      if (frameWindow) {
        const isProfileComplete = this.isUserProfileValid()
        frameWindow.postMessage({
          type: SdkMessageType.VERIFY_PROFILE_COMPLETION_STATUS,
          category: SdkMessageCategory.RESPONSE,
          value: {
            isProfileComplete,
            pageName: message.data.pageName,
          },
        }, this.#windowController.targetOrigin)
      }
    }
    if (message.data.type === SdkMessageType.LOGOUT_AND_CLOSE) {
      this.#unauthenticate(
        SignOutType.MissingRequiredFields,
        message.data.pageName,
        message.data.pageType,
      )
      const frameWindow = this.#windowController.getWindow()
      if (frameWindow) {
        frameWindow.postMessage({
          type: SdkMessageType.LOGOUT_AND_CLOSE,
          category: SdkMessageCategory.RESPONSE,
          value: {},
        }, this.#windowController.targetOrigin)
      }
    }
    if (message.data.type) {
      if (message.data.type === SdkMessageType.STORE_OPT_IN_AGREEMENT_IDENTIFIERS) {
        if (!this.profile) return

        const agreedTermsIdentifiers = message.data.value
        const optInsList = ConfigHelpers.getConfigOptIns(this.#config)
        if (optInsList) {
          agreedTermsIdentifiers.forEach((identifier) => {
            const configOptInDescription =
              optInsList.find((optIn) => optIn.identifier === identifier)

            if (configOptInDescription) {
              const cookieIdentifier = createOptInAgreementCookieName(identifier, this.profile._id)
              const existingCookie = getCookie(cookieIdentifier)
              if (!existingCookie) {
                const expirationDate = getCookieExpirationDate(
                  configOptInDescription.expirationDate,
                )
                setCookie(
                  cookieIdentifier,
                  true,
                  expirationDate,
                  this.#env,
                  this.#crossDomain,
                )
                storeOptInCookie(
                  cookieIdentifier,
                  true,
                  expirationDate,
                  this.#env,
                  this.#crossDomain,
                )
              }
            }
          })
        }

        const profile = {
          ...this.profile,
          optInAgreementIdentifiers: getExistingOptInAgreementIdentifiers(
            this.profile,
            optInsList,
          ),
        }
        this.#windowController.updateProfile(profile)
        this.#setState({ profile })
      }

      if (message.data.type === SdkMessageType.CLOSE) {
        this.#windowController.disableMessaging()
        this.#setState({
          windowState: WindowState.CLOSED,
          closeReason: message.data.reason,
          ...(this.profile ? {} : { registrationResult: RegistrationResult.CANCELLED }),
        })
        if (this.#crossDomain) {
          /**
           * Return to client site
           */
          window.history.back()
        }
      }

      if (message.data.type === SdkMessageType.PASSKEY_CREATE_CREDS) {
        const frameWindow = this.#windowController.getWindow()
        let authId

        const handlePasskeyCreationError = (err, errorSystem) => {
          if (authId) {
            this.#idmApiClient.finalizePasskeyCreation(
              authId,
              { errorMessage: err },
            )

            authId = null
          }

          frameWindow.postMessage({
            type: SdkMessageType.PASSKEY_CREATE_CREDS_RESPONSE,
            category: SdkMessageCategory.RESPONSE,
            value: {
              errorSystem,
              response: err,
              error: true,
            },
          }, this.#windowController.targetOrigin)
        }

        try {
          this.#passkeyAbortController?.abort()
          this.#passkeyAbortController = new AbortController()

          const initResponse = await this.#idmApiClient.initPasskey(PasskeyAuthMode.REGISTRATION)

          authId = initResponse.authcontext.authId
          const publicKey = createPublicKey(initResponse.authcontext.metadata)

          let newCredentialInfo
          try {
            newCredentialInfo = await navigator.credentials.create({
              publicKey,
              signal: this.#passkeyAbortController.signal,
            })
          } catch (err) {
            handlePasskeyCreationError(
              err,
              createComputedPropertyLessClone(AnalyticsErrorSystem.PASSKEY),
            )
            return
          }

          const rawId = newCredentialInfo.id
          const clientData = String.fromCharCode.apply(
            null,
            new Uint8Array(newCredentialInfo.response.clientDataJSON),
          )
          const keyData = new Int8Array(newCredentialInfo.response.attestationObject).toString()

          const requestAuthId = authId
          authId = null

          const response = await this.#idmApiClient.finalizePasskeyCreation(
            requestAuthId,
            { clientData, keyData, rawId },
          )

          if (frameWindow) {
            frameWindow.postMessage({
              type: SdkMessageType.PASSKEY_CREATE_CREDS_RESPONSE,
              category: SdkMessageCategory.RESPONSE,
              value: {
                response,
                error: false,
              },
            }, this.#windowController.targetOrigin)
          }
        } catch (err) {
          handlePasskeyCreationError(err, createComputedPropertyLessClone(AnalyticsErrorSystem.IDM))
        }
      }
    }

    if (message.data.type === SdkMessageType.PASSKEY_AUTH_CREDS) {
      this.#handlePasskeyAuthentication(message.data.value.mediation)
    }

    if (message.data.type === SdkMessageType.OAUTH_REDIRECT) {
      const encodedEmail = btoa(message.data.value)
      const redirectUrl = this.#config?.crossDomain?.redirectUrl
      const options = {}
      const defaultPage = 'enterOneTimeCode'
      this.#redirect(redirectUrl, options, encodedEmail, defaultPage)
    }
  }

  /**
     * Register callback to be called with events of specified type.
     *
     * Callback will be called with an argument depending on the type of event.
     *
     * @example
     * const observable = new Observable()
     * const off = observable.on('foo', (data) => {
     *   console.log(`Foo!`, data)
     * })
     *
     *
     * @param {ObservableType} type - Observable event type
     * @param {Function} callback - Event callback function
     * @return {Function} - Function to be called to unregister this callback
     */
  on(type, callback) {
    if (typeof type !== 'string') {
      throw new Error('Type argument passed to Observable#on() is not specified or not a string')
    }
    if (typeof callback !== 'function') {
      throw new Error('Callback function passed to Observable#on() is not specified or not a function')
    }

    if (!this.#observers[type]) {
      this.#observers[type] = []
    }

    if (this.#observers[type].indexOf(callback) === -1) {
      this.#observers[type].push(callback)
    }

    switch (type) {
      case ObservableTypes.STATUS:
        callback(this.#state.status)
        break
      case ObservableTypes.AUTHENTICATION_STATE:
        callback(this.#state.authenticationState)
        break
      case ObservableTypes.USER:
        callback(this.#state.profile)
        break
      case ObservableTypes.PROFILE:
        callback(this.#state.profile)
        break
      default:
        break
    }

    return () => {
      const typeObservers = this.#observers[type]
      const index = typeObservers.indexOf(callback)
      if (index >= 0) {
        typeObservers.splice(index, 1)
      }
    }
  }

  /**
   * Public method to expose the user's profile
   */
  getUserDetails() {
    return this.profile
  }

  #notify(type, data, ...otherArgs) {
    try {
      if (Array.isArray(this.#observers[type])) {
        this.#observers[type].forEach((callback) => {
          callback(data, ...otherArgs)
        })
      }
    } catch (error) {
      this.#log(error)
    }
  }

  get customEventAttributes() {
    return this.#customEventAttributes
  }

  /**
     * @param {} attributes
     */
  set customEventAttributes(attributes) {
    this.#customEventAttributes = attributes
    if (this.#eventLogger != null) {
      this.#eventLogger.customEventAttributes = attributes
    }
  }

  get apis() {
    return APIs
  }

  #updateAuthentication(parkFr) {
    if (parkFr) {
      setTimeout(() => {
        const profile =
          IdentityRecord.restore(this.#config)?.profile

        if (profile) {
          if (this.#isProfileComplete(profile)) {
            this.#setState({
              authenticationState: AuthenticationState.AUTHENTICATED,
              registrationResult: RegistrationResult.LOGGED_IN,
              profile,
            })
          }
        } else {
          this.#validateStoredProfile()
        }
      }, 300)
    } else {
      this.unauthenticate()
    }
  }

  #onAuthenticationStatusChange(status) {
    try {
      if (
        this.#eventLogger &&
        !this.#eventLogger.isInitialized &&
        status !== AuthenticationState.UNKNOWN &&
        status !== AuthenticationState.UNAUTHENTICATING
      ) {
        const { profile } = this.#state ?? {}
        const { analytics } = this.#config ?? {}
        this.#eventLogger.setUp(profile, analytics?.mParticle?.dataPlan)
      }
    } catch (error) {
      console.error('Unexpected error at identity.js #onAuthenticationStatusChange', error)
    }
  }

  async getBrandLogos() {
    const logos = await this.#idmApiClient.getBrandLogos()
    return logos.map(createComputedPropertyLessClone)
  }

  #getVersionNumber(version) {
    if (!version) return IDENTITY_SDK_VERSION?.replace(/^v/, '')
    return version?.replace(/^v/, '')
  }
}
