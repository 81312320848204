export const SdkStatus = Object.freeze({
  UNINITIALIZED: 'uninitialized',
  INITIALIZING: 'initializing',
  INITIALIZED: 'initialized',
})

export const AuthenticationState = Object.freeze({
  AUTHENTICATED: 'authenticated',
  UNAUTHENTICATING: 'unauthenticating',
  UNAUTHENTICATED: 'unauthenticated',
  UNKNOWN: 'unknown',
})

export const RegistrationResult = Object.freeze({
  REGISTERED: 'registered',
  LOGGED_IN: 'logged in',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  LOGGED_OUT: 'logged out',
})

export const EventLoggerStatus = Object.freeze({
  UNINITIALIZED: 'uninitialized',
  INITIALIZING: 'initializing',
  INITIALIZED: 'initialized',
  ERROR: 'error',
  READY: 'ready',
})

export const WindowState = Object.freeze({
  CLOSED: 'closed',
  OPENING: 'opening',
  OPENED: 'opened',
})

export const configOverwriteSchema = Object.freeze({
  brand: {
    product: null,
  },
  theme: {
    branding: {
      horizontalLogo: null,
    },
  },
  crossDomain: {
    headerLogo: null,
    oAuthBrandLogo: null,
  },
})
