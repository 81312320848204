import {
  getDomainIfNoSDKLocation,
  getUrlIfSdkLocation,
  isLocalDomain,
} from './helpers/domainHelper'

/**
 * Identity server
 *
 * @property {string} env - ENvironmenet to use, e.g: dev/stage/..
 * @property {string} sdkVersion - Identity SDK version
 * @property {string} timeout - Default request in seconds (0 to disable)
 * @property {string} url - Base URL
 */
class IdentityServer {
  /**
     * IdentityServer constructor
     *
     * @param {Object} [options={}] Options
     * @param {string} [options.env='production'] - Environment to use, e.g: dev/stage/..
     * @param {string} [options.sdkVersion='test'] - Identity server API version
     * @param {number} [options.timeout=20] - Default request timeout in seconds (0 to disable)
     * @param {boolean} [options.crossDomain=false] - Is SDK running on crossDomain mode
     */
  constructor({
    checkoutEnabled = false,
    env = 'production',
    sdkVersion = 'test',
    useBaseSdkLocation = false,
    timeout = 20,
    crossDomain = false,
  } = {}) {
    this.timeout = timeout
    this.crossDomain = crossDomain
    this.updateConfiguration({ checkoutEnabled, env, sdkVersion, useBaseSdkLocation })
  }

  updateConfiguration({ checkoutEnabled, env, sdkVersion, useBaseSdkLocation }) {
    if (this.checkoutEnabled === checkoutEnabled && this.env === env &&
            this.sdkVersion === sdkVersion && this.useBaseSdkLocation === useBaseSdkLocation) {
      return
    }

    this.checkoutEnabled = checkoutEnabled
    this.env = env
    this.useBaseSdkLocation = useBaseSdkLocation
    this.sdkVersion = sdkVersion
    if (this.useBaseSdkLocation) {
      this.url = getUrlIfSdkLocation(env)
    } else {
      this.url = getDomainIfNoSDKLocation(env, this.crossDomain)
    }
  }

  getSdkUrl(path = '') {
    return new URL(path, `${this.url}/websdk/`)
  }

  /**
     *
     * @param {String} [path=''] - SDK Request path
     * @param {*} [customBaseUrl=null] - Custom SDK base URL
     * @return {String} - SDK URL with specified path
     */
  getResourcesUrl(path = '', customBaseUrl = null) {
    const isUrl = (string) => {
      try { return Boolean(new URL(string)) } catch (e) { return false }
    }
    const url = isUrl(customBaseUrl) ? new URL(path, customBaseUrl) : this.getSdkUrl(path)
    return url.toString()
  }

  /**
     * Requests a response from an SDK path
     *
     * See {@link IdentityServer#getResourcesUrl} for details on the URL that is used.
     * See {@link IdentityServer#request} for supported options etc.
     *
     * @param {String} key - Preferred Brand key - Fallbacks to "nbc" if not set
     * @param {String} [language] - Preferred language - Fallbacks to "en" if not set
     * @param {String} [customBaseUrl=null] - Custom SDK base URL
     * @param {Object} [options={}] - Request options
     * @returns {Promise} - Promise of JSON parsed response body
     */
  fetchBrandConfigFile(key, language, customBaseUrl = null, options = {}) {
    const languageSuffix = language && language !== 'en' ? `-${language}` : ''
    const filename = `${this.checkoutEnabled ? 'checkout-' : ''}${key}${languageSuffix}.json`
    const filePath = `${customBaseUrl ? '' : 'config/'}${filename}`
    return this.#request(this.getResourcesUrl(filePath, customBaseUrl), options)
  }

  fetchIdentityConfigFile() {
    let url

    if (!this.useBaseSdkLocation && isLocalDomain()) {
      url = `https://${window.location.hostname}:3000/config/${this.env}/v2-identity-config.json`
    } else {
      const envSuffix = this.env !== 'production' ? `${this.env}.id-envs` : 'id'
      url = `https://${envSuffix}.nbcuni.com/websdk/config/v2-identity-config.json`
    }

    return this
      .#request(new URL(url).toString(), { timeout: 3 })
      .then((response) => ({
        ...response,
        forgeRock: {
          ...(response?.forgeRock ?? {}),
          apiVersion: '2024.1',
        },
      }))
  }

  /**
     * Requests specified path (or URL) and returns a Promise of JSON parsed response body
     *
     * If response is not OK the returned Promise is rejected
     *
     * If the returned Promise is rejected, the associated Error instance is decorated with status
     * (number), statusText (string) and return (function) properties.
     * The retry function can be called to retry the request.
     *
     * If a body is specified it will be JSON stringified and a Content-Type: application/json
     * header will be added.
     *
     * If a timeout is specified that will be used, otherwise the default IdentityServer timeout
     * will be used.
     *
     * Besides these options, standard fetch init properties (e.g: method) are supported as
     * options as well.
     * @see https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
     *
     * @param {string} pathOrUrl - Request path or URL
     * @param {Object} [options={}] - Options
     * @param {Object} [options.headers={}] - Request headers
     * @param {Object} [options.body] - Request body
     * @param {number} [options.timeout] - Request timeout in seconds (0 to disable)
     * @return {Promise} - Promise of JSON parsed response body
     */
  #request(pathOrUrl, options = {}) {
    const { headers = {}, body, timeout = this.timeout, ...init } = options
    const retry = () => this.request(pathOrUrl, options)

    // Convert headers
    init.headers = new Headers(headers)

    if (body) {
      init.headers.set('Content-Type', 'application/json')
      init.body = JSON.stringify(body)
    }

    const url = new URL(pathOrUrl)

    const promise = fetch(url.toString(), init).then((response) => {
      const { ok, status, statusText } = response

      // Check headers to make sure response is JSON format
      const contentType = response.headers.get('content-type')
      if (!contentType || contentType.indexOf('application/json') === -1) {
        const error = new Error('Response must be JSON')
        throw Object.assign(error, { code: 422 })
      }

      return response.json().then((responseBody) => {
        if (ok) {
          return responseBody
        }
        // Reject if response status not OK and decorate error with any responseBody properties
        const error = new Error(`Response not OK (${status} - ${statusText})`)
        throw Object.assign(error, responseBody)
      }).catch((error) => {
        // And always decorate error with status properties and retry function
        throw Object.assign(error, { status, statusText, retry })
      })
    })

    if (!timeout) return promise

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Like above, decorate error with status properties and retry function
        const error = new Error(`Timeout after ${timeout} seconds`)
        Object.assign(error, { status: 0, statusText: 'Timeout', retry })
        reject(error)
      }, timeout * 1000)
      promise.then(resolve, reject)
    })
  }
}

export default IdentityServer
